@tailwind base;
@tailwind components;
@tailwind utilities;

*
{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

html
{
    background: black;
    overflow-x: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

.section
{
    
    display: block;
    align-items: center;
    min-height: 100vh;
    padding-left: 10%;
    padding-right: 10%;

}

